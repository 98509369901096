import { FILTERS_EQUAL } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("filter-drawer-composer", {
    attrs: {
      "has-filter-inactive": false
    }
  }, [_c("select-filter-v-2", {
    key: "Whs",
    attrs: {
      "mode": "multiple",
      "has-option-all": true,
      "label": "Whs",
      "reference": "export-order.common.whs",
      "source": "code",
      "source-label": "name",
      "placeholder": "Choose Whs",
      "delete-filter": "IsInactive",
      "advanced-filters": _vm.customFilter
    }
  }), _c("select-filter-v-2", {
    key: "Dvsn",
    attrs: {
      "mode": "multiple",
      "has-option-all": true,
      "label": "Dvsn",
      "reference": "export-order.common.dvsn",
      "source": "code",
      "source-label": "name",
      "placeholder": "Choose Dvsn",
      "delete-filter": "IsInactive",
      "advanced-filters": _vm.customFilter
    }
  }), _c("select-filter-v-2", {
    key: "Chnl",
    attrs: {
      "mode": "multiple",
      "has-option-all": true,
      "label": "Chnl",
      "reference": "export-order.common.chnl",
      "source": "code",
      "source-label": "name",
      "placeholder": "Choose Chnl",
      "delete-filter": "IsInactive",
      "advanced-filters": _vm.customFilter
    }
  }), _c("select-filter-v-2", {
    key: "Brnd",
    attrs: {
      "label": "Brand",
      "reference": "export-order.common.brand",
      "source": "code",
      "source-label": "name",
      "placeholder": "Choose Brand",
      "mode": "multiple",
      "has-option-all": true,
      "delete-filter": "IsInactive"
    }
  }), _c("operator-filter", {
    key: "CustPO",
    attrs: {
      "label": "Cust PO",
      "type": "string"
    }
  }), _c("operator-filter", {
    key: "LoadNo",
    attrs: {
      "label": "Load No",
      "type": "string"
    }
  }), _c("operator-filter", {
    key: "Stat",
    attrs: {
      "label": "Stat",
      "type": "string"
    }
  }), _c("select-filter-v-2", {
    key: "Stat",
    attrs: {
      "label": "Stat Descr",
      "reference": "export-order.common.stat-descr",
      "source": "code",
      "source-label": "name",
      "placeholder": "Choose Stat Descr",
      "mode": "multiple",
      "has-option-all": true,
      "delete-filter": "IsInactive"
    }
  }), _c("operator-filter", {
    key: "VesselVoyage",
    attrs: {
      "type": "string",
      "label": "Vessel Voyage",
      "placeholder": "Vessel Voyage"
    }
  }), _c("operator-filter", {
    key: "BookingNo",
    attrs: {
      "type": "string",
      "label": "Booking Number",
      "placeholder": "Booking Number"
    }
  }), _c("operator-filter", {
    key: "DocsSharedDate",
    attrs: {
      "type": "date",
      "label": "Docs Shared Date",
      "placeholder": "Docs Shared Date"
    }
  }), _c("operator-filter", {
    key: "DateDocsDue",
    attrs: {
      "type": "date",
      "label": "Date Docs Due",
      "placeholder": "Date Docs Due"
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "FilterAdvanced",
  inject: ["crud"],
  data() {
    const selectedCountry = this.crud.getFilter("CountryCode");
    const customFilter = [
      [
        "CountryCode",
        {
          operator: FILTERS_EQUAL,
          value: selectedCountry ? selectedCountry.value : null
        }
      ]
    ];
    return {
      customFilter
    };
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var FilterAdvanced = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "filter-bunker"
  }, [_c("resource", {
    attrs: {
      "name": "export-order.common.whs",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "export-order.common.brand",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "export-order.common.chnl",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "export-order.common.dvsn",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "export-order.common.stat-descr",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "export-order.export-order-data",
      "api-url": _vm.apiUrl,
      "redirect-route": "/export-orders/export-orders-data"
    }
  }, [_c("filter-advanced")], 1)], 1);
};
var staticRenderFns = [];
const __vue2_script = {
  components: {
    FilterAdvanced
  },
  data() {
    return {
      apiUrl: "#{VUE_APP_API_URL}#"
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
